import React from 'react';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import useTheme from '../../../hooks/useTheme';
import ModuleWrapper from '../../ModuleWrapper';

function RetinaWhyIronhack(props, ref): JSX.Element {
  const { t } = useTranslation(['retina-landing']);
  const { layout } = useTheme();

  return (
    <ModuleWrapper
      px={[0, layout.desktop.padding]}
      justifyContent={[null, 'space-between']}
      ref={ref}
    >
      <Box order={[-1, 1]} mt={[0, 4]}>
        <Image
          src="/assets/retina/why-ironhack.png"
          width="100%"
          sx={{ borderRadius: [0, 'card'] }}
        />
      </Box>
      <Box
        width={['100%', '44.5rem']}
        mt={[6, 0]}
        px={[layout.mobile.padding, 0]}
      >
        <Text
          as="h2"
          variant="title1"
          color="darkBlue"
          sx={{ '&>span': { color: 'electricBlue' } }}
          dangerouslySetInnerHTML={{
            __html: t('retinaLanding.whyIronhack.title')
          }}
        />
        <Box mt={4}>
          <Text variant="body4" color="darkBlues.64">
            {t('retinaLanding.whyIronhack.paragraphs.first')}
            <br />
            <br />
            {t('retinaLanding.whyIronhack.paragraphs.second')}
          </Text>
        </Box>
        <Box
          mt={6}
          p={5}
          sx={{ borderRadius: 'card' }}
          backgroundColor="greens.20"
        >
          <Text
            as="p"
            variant="body4"
            color="darkBlue"
            dangerouslySetInnerHTML={{
              __html: t('retinaLanding.whyIronhack.card')
            }}
          />
        </Box>
      </Box>
    </ModuleWrapper>
  );
}

export default React.forwardRef<HTMLDivElement>(RetinaWhyIronhack);
