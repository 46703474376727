export default {
  variants: {
    emphasis: {
      color: 'darkBlues.80',
      fontWeight: 600,
      paddingBottom: 1,
      borderBottomWidth: '1px',
      borderBottomColor: 'darkBlues.64',
      borderBottomStyle: 'solid',
      // TODO: text decoration reset can be removed once
      // Chakra reset is able to be done
      textDecoration: 'none',
      _hover: {
        color: 'darkBlues.100',
        borderBottomColor: 'darkBlues.80',
        textDecoration: 'none'
      }
    }
  }
};
