import { updateDataLayer } from '../../../lib/datalayer';

const interestEventMap: {
  [key: string]: string;
} = {
  web: 'wb',
  uxui: 'ux',
  data: 'da',
  cyber: 'cy'
};

function emitInterestEvent(course: string) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: `${interestEventMap[course]}::download syllabus`
  });
}
export { emitInterestEvent };
