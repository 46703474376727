import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

function RetinaHeroContent(): JSX.Element {
  const { t } = useTranslation(['retina-landing']);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      alignItems={['center', 'flex-start']}
    >
      <Text
        as="h1"
        fontFamily="Gelion-SemiBold"
        color="white"
        fontSize={['5.6rem', '8.8rem']}
        lineHeight={['4.48rem', '7.04rem']}
      >
        {t('retinaLanding.hero.content.title1')}{' '}
      </Text>
      <Box ml={[0, 7]}>
        <Text
          as="h1"
          fontFamily="Gelion-Regular"
          color="white"
          fontSize={['5.6rem', '8.8rem']}
          lineHeight={['4.48rem', '7.04rem']}
        >
          {t('retinaLanding.hero.content.title2')}
        </Text>
      </Box>
      <Box mt={[6, 7]} width={['100%', '54.2rem']}>
        {/* Hack because apparently variants cannot use an array for breakpoints */}
        <Text
          as="h2"
          color="white"
          fontFamily="Roboto"
          fontWeight={400}
          fontSize={['1.6rem', '2rem']}
          lineHeight={['2.23rem', '3rem']}
          textAlign={['center', 'left']}
          dangerouslySetInnerHTML={{
            __html: t('retinaLanding.hero.content.text')
          }}
        />
      </Box>
    </Flex>
  );
}

export default RetinaHeroContent;
