import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import RetinaFeatureCard from './components/RetinaFeatureCard';
import ModuleWrapper from '../../ModuleWrapper';

function RetinaFeatures(): JSX.Element {
  const { t } = useTranslation(['retina-landing']);

  return (
    <ModuleWrapper>
      <Flex width={[null, '25.6rem']} height={['11.4rem', '16rem']}>
        <RetinaFeatureCard icon="lightning">
          <Text
            as="p"
            variant="body4"
            textAlign="center"
            fontWeight="700"
            dangerouslySetInnerHTML={{
              __html: t('retinaLanding.features.first.primary')
            }}
          />
          <Text as="p" variant="body4" textAlign="center">
            {t('retinaLanding.features.first.secondary')}
          </Text>
        </RetinaFeatureCard>
      </Flex>
      <Flex
        mt={[4, 0]}
        ml={[0, 6]}
        width={[null, '25.6rem']}
        height={['11.4rem', '16rem']}
      >
        <RetinaFeatureCard icon="glasses">
          <Text
            as="p"
            variant="body4"
            textAlign="center"
            fontWeight="700"
            dangerouslySetInnerHTML={{
              __html: t('retinaLanding.features.second.primary')
            }}
          />
        </RetinaFeatureCard>
      </Flex>
      <Flex
        mt={[4, 0]}
        ml={[0, 6]}
        width={[null, '25.6rem']}
        height={['11.4rem', '16rem']}
      >
        <RetinaFeatureCard icon="dumbell">
          <Text
            as="p"
            variant="body4"
            textAlign="center"
            fontWeight="700"
            dangerouslySetInnerHTML={{
              __html: t('retinaLanding.features.third.primary')
            }}
          />
        </RetinaFeatureCard>
      </Flex>
      <Flex
        mt={[4, 0]}
        ml={[0, 6]}
        width={[null, '25.6rem']}
        height={['11.4rem', '16rem']}
      >
        <RetinaFeatureCard icon="cilinder">
          <Text
            as="p"
            variant="body4"
            textAlign="center"
            fontWeight="700"
            dangerouslySetInnerHTML={{
              __html: t('retinaLanding.features.fourth.primary')
            }}
          />
        </RetinaFeatureCard>
      </Flex>
    </ModuleWrapper>
  );
}

export default RetinaFeatures;
