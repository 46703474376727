import _ from 'lodash/fp';
import fetch from 'node-fetch';

import defaultCampusByLanguage from './default-campus';
import { getUserLocationQuery } from './graphql';
import {
  GetCampusVisited,
  GetUserLocation,
  SaveUserLocation
} from '../storage/provider';

const PRIORITY_USER_INTERACTION = 3,
  PRIORITY_GEOLOCATION = 2,
  PRIORITY_LANGUAGE = 1,
  PRIORITY_UNKNOWN = 0;

export default async function getDefaultLocation(language: string) {
  const storedLocation = GetUserLocation();
  const { location, priority } = storedLocation || {
    location: { campus: '', remoteTimezone: '' },
    priority: { campus: PRIORITY_UNKNOWN, remoteTimezone: PRIORITY_UNKNOWN }
  };

  // First priority (campus) - User already visited a campus
  if (priority.campus < PRIORITY_USER_INTERACTION) {
    const visitedCampus = GetCampusVisited();
    if (visitedCampus) {
      location.campus = visitedCampus;
      priority.campus = PRIORITY_USER_INTERACTION;
    }
  }

  // Second priority (campus, timezone) - User geolocation
  const campusNeedsGeolocation = priority.campus < PRIORITY_GEOLOCATION;
  const tzNeedsGeolocation = priority.remoteTimezone < PRIORITY_GEOLOCATION;
  if (campusNeedsGeolocation || tzNeedsGeolocation) {
    await fetch(process.env.IRONHACK_API, {
      ...getUserLocationQuery(),
      timeout: 2000 // This function can block critical UI as the applyform
    })
      .then(_.invoke('json'))
      .then(
        (response: { data: { campus: string; remoteTimezone: string } }) => {
          if (campusNeedsGeolocation) {
            location.campus = response.data.campus;
            priority.campus = PRIORITY_GEOLOCATION;
          }
          if (tzNeedsGeolocation) {
            location.remoteTimezone = response.data.remoteTimezone;
            priority.remoteTimezone = PRIORITY_GEOLOCATION;
          }
        }
      )
      .catch(_.noop);
  }

  // Third priority (campus) - Makes an assumption based on page language
  if (language && priority.campus < PRIORITY_LANGUAGE) {
    location.campus = defaultCampusByLanguage(language);
    priority.campus = PRIORITY_LANGUAGE;
  }

  SaveUserLocation({ location, priority });
  return location;
}
