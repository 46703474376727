import React from 'react';
import { Flex } from 'rebass/styled-components';

const icons = {
  lightning: {
    backgroundImage: `url('/assets/retina/lightning.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundColor: 'lightcyan'
  },
  glasses: {
    backgroundImage: `url('/assets/retina/glasses.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 95%',
    backgroundColor: 'lavender'
  },
  dumbell: {
    backgroundImage: `url('/assets/retina/dumbell.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '5px 0%',
    backgroundColor: 'oldlace'
  },
  cilinder: {
    backgroundImage: `url('/assets/retina/cilinder.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundColor: 'whitesmoke'
  }
};

function RetinaFeatureCard(props: Props): JSX.Element {
  const { children, icon = 'lighning' } = props;
  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ borderRadius: 'card', ...icons[icon] }}
    >
      {children}
    </Flex>
  );
}

export default RetinaFeatureCard;
