import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from 'rebass/styled-components';
import Card from '../../../../components/atoms/layout/Card';

export default (props: any): JSX.Element => {
  const { title, description, Icon, onClickDownload, price } = props;
  const { t } = useTranslation(['retina-landing']);

  return (
    <Card
      width={['', 544]}
      bg={'white'}
      borderRadius="card"
      boxShadow={[
        'active2',
        '10.0555px 10.0555px 20.1109px rgba(0, 0, 0, 0.08);'
      ]}
    >
      <Flex flexDirection={'column'}>
        <Flex flexDirection={['column', 'row']}>
          <Box ml={[4, 5]} mt={[4, 5]} width={48} height={48}>
            <Icon />
          </Box>
          <Text color="darkBlue" ml={4} mt={[4, 5]} variant="title2">
            {title}
          </Text>
        </Flex>
        <Text mx={[4, 5]} mt={4} color="darkBlues.80" variant="body4">
          {description}
        </Text>
        {price && (
          <Text
            mx={[4, 5]}
            mt={4}
            fontWeight="bold"
            color="darkBlues.80"
            variant="body4"
          >
            {`${t(
              'retina-landing:retinaLanding.courses.tuitionFrom'
            )} ${price}`}
          </Text>
        )}
        <Button
          width={['', 1 / 2]}
          height={[44, 49]}
          mx={[4, 5]}
          mt={[5, 6]}
          mb={[4, 5]}
          onClick={onClickDownload}
          variant="primaryS"
        >
          {t('retina-landing:retinaLanding.courses.downloadSyllabus')}
        </Button>
      </Flex>
    </Card>
  );
};
