export default {
  baseStyle: {
    bg: 'white',
    color: '#1E2334',
    fontSize: 1,
    p: '16px',
    borderRadius: 'button',
    boxShadow: 'active3'
  }
};
