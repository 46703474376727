import React from 'react';
import { Box, Flex, Text, Button } from 'rebass/styled-components';

import { ButtonsPrimary } from '../../../../../components/atoms/buttons';

interface Props {
  title: string;
  subtitle: string;
  cta: string;
  picture: string;
  onCtaClick: Function;
}

function RetinaStory(props: Props): JSX.Element {
  const { title, subtitle, cta, picture, onCtaClick } = props;

  return (
    <Flex
      height={['100%']}
      width="100%"
      flexDirection="column"
      justifyContent="flex-end"
      sx={{
        borderRadius: 'card',
        boxShadow: 'active2',
        backgroundImage: `url(${picture})`,
        backgroundSize: 'cover'
      }}
      p={[4, 6]}
    >
      <Text as="h3" variant="body1" color="white">
        {title}
      </Text>
      <Box mt={3}>
        <Text as="p" variant="body4" color="white">
          {subtitle}
        </Text>
      </Box>
      {cta && (
        <Button
          mt={5}
          onClick={onCtaClick}
          width={['100%', '320px']}
          variant="primaryS"
        >
          {cta}
        </Button>
      )}
    </Flex>
  );
}

export default RetinaStory;
