const buttonBase = {
  backgroundColor: 'electricBlue',
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: ['1.5rem', '1.8rem'],
  color: 'white',
  px: '4.8rem',
  py: ['1rem', '1.4rem'],
  borderRadius: 'button',
  cursor: 'pointer'
};

const primaryS = buttonBase;
const primaryM = {
  ...buttonBase,
  fontSize: ['1.5rem', '2rem'],
  py: ['1rem', '2.4rem']
};
const primaryL = {
  ...primaryM,
  width: [null, '35.7rem']
};
const primaryXL = {
  ...primaryM,
  width: [null, '42.6rem']
};

const outlineBlue = {
  ...primaryM,
  backgroundColor: 'transparent',
  color: 'electricBlue',
  boxShadow: 'outlineBlue'
};

const outlineGray = {
  ...outlineBlue,
  boxShadow: 'outlineGray'
};

export default {
  buttons: {
    primaryS,
    primaryM,
    primaryL,
    primaryXL,

    outlineBlue,
    outlineGray
  }
};
