import React from 'react';
import { Text, Button, Flex } from '@chakra-ui/react';

function ContextualCtas(props: any): JSX.Element {
  const { content, onPrimaryClick, onSecondaryClick } = props;
  const { header, title, body, primaryButton, secondaryButton } = content;

  return (
    <Flex
      alignItems="center"
      backgroundColor="electricBlues.10"
      px={[3, 10]}
      py={[2, 4]}
      direction={['column', 'row']}
    >
      <Flex grow={2} basis="1" direction="column">
        <Text
          fontSize={0}
          fontWeight="500"
          color="electricBlues.100"
          font="Roboto Mono"
        >
          {header}
        </Text>
        <Text mt={1} variant="body1">
          {title}
        </Text>
        <Text mt={1} color="darkBlues.80" variant="body4">
          {body}
        </Text>
      </Flex>
      <Button
        onClick={onPrimaryClick}
        mt={[3, '']}
        ml={['', 4]}
        width={['100%', '256px']}
        height={['44px', '80px']}
        variant="primaryS"
      >
        {primaryButton}
      </Button>
      <Button
        onClick={onSecondaryClick}
        mt={[2, '']}
        ml={['', 4]}
        width={['100%', '256px']}
        height={['44px', '80px']}
        variant="outlineBlue"
      >
        {secondaryButton}
      </Button>
    </Flex>
  );
}

export default ContextualCtas;
