export default {
  fonts: {
    accent: 'Gelion',
    heading: 'Gelion',
    body: 'Roboto',
    jumbo: 'Gelion-SemiBold',
    title1: 'Gelion-SemiBold',
    title2: 'Gelion-SemiBold',
    body1: 'Gelion-SemiBold',
    body2: 'Gelion-SemiBold',
    body3: 'Roboto',
    body4: 'Roboto',
    body5: 'Roboto',
    body6: 'Roboto'
  },
  fontWeights: {
    jumbo: 600,
    title1: 600,
    title2: 600,
    body1: 600,
    body2: 700,
    body3: 400,
    body4: 400,
    body5: 400,
    body6: 500
  },
  lineHeights: [
    '1.82rem',
    '2.24rem',
    '2.52rem',
    '2.6rem',
    '3rem',
    '3.12rem',
    '3.2rem',
    '4rem',
    '4.8rem',
    '6rem'
  ],
  fontSizes: [
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.4rem',
    '2.6rem',
    '3.2rem',
    '3.8rem',
    '4rem',
    '4.8rem',
    '5.6rem'
  ],
  // fontsSizes: {
  //   jumbo: '5.6rem',
  //   title1: '4rem',
  //   title2: '3.2rem',
  //   body1: '2.4rem',
  //   body2: '2rem',
  //   body3: '2rem',
  //   body4: '1.6rem',
  //   body5: '1.4rem'
  // },
  space: [0, 1, 2, 4, 8, 16, 32, 64],
  textStyles: {
    h1: {
      fontSize: [5, 8],
      fontWeight: 600
    },
    h2: {
      fontSize: [4, 6],
      fontWeight: 600
    },
    body3: {
      fontSize: 2,
      fontWeight: 400,
      color: 'darkBlues.100'
    },
    body4: {
      fontSize: 1,
      fontWeight: 400,
      color: 'darkBlues.100'
    }
  },
  text: {
    jumbo: {
      fontFamily: 'jumbo',
      lineHeight: 'jumbo',
      fontWeight: 'jumbo',
      letterSpacing: '-0.5px',
      fontSize: [5, 7, 7]
    },
    title1: {
      fontFamily: 'title1',
      fontWeight: 'title1',
      letterSpacing: '-0.5px',
      fontSize: [6, 8],
      lineHeight: [5, 8]
    },
    title2: {
      fontFamily: 'title2',
      lineHeight: 'title2',
      fontWeight: 'title2',
      letterSpacing: '-0.5px',
      fontSize: [4, 6],
      lineHeight: [3, 8]
    },
    body1: {
      fontFamily: 'body1',
      lineHeight: 'body1',
      fontWeight: 'body1',
      letterSpacing: '-0.5px',
      fontSize: [3, 4]
    },
    body2: {
      fontFamily: 'body2',
      lineHeight: 'body2',
      fontWeight: 'body2',
      fontSize: [3, 4]
    },
    body3: {
      fontFamily: 'body3',
      fontWeight: 'body3',
      fontSize: [2, 3],
      lineHeight: [2, 4]
    },
    body4: {
      fontFamily: 'body4',
      lineHeight: [1],
      fontWeight: 'body4',
      fontSize: [1]
    },
    body5: {
      fontFamily: 'body5',
      lineHeight: 'body5',
      fontWeight: 'body5',
      fontSize: [0]
    },
    body6: {
      fontFamily: 'body6',
      lineHeight: '1.56rem',
      fontWeight: 'body6',
      fontSize: '1.2rem'
    }
  }
};
