import _ from 'lodash/fp';
import React, { useState, useMemo } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import StoryCard from './components/RetinaStory';
import RetinaStoryModal from './components/RetinaStoryModal';
import ModuleWrapper from '../../ModuleWrapper';

type AlumniId = 'josh' | 'sofia' | 'marta';

function RetinaStories(props, ref): JSX.Element {
  const { handleClickApply, handleClickRequest, testimonials } = props;

  const { t } = useTranslation(['retina-landing']);

  const stories = useMemo(() => buildStoriesData(testimonials, t), [
    testimonials
  ]);

  const [modalOpen, setModalOpen] = useState(false);
  const [testimonial, setTestimonial] = useState('');
  const [modalImage, setModalImage] = useState('');

  const selectTestimonial = (alumniId: AlumniId) => {
    setTestimonial(stories[alumniId].testimonial);
    setModalImage(stories[alumniId].card.picture);
    setModalOpen(true);
  };

  return (
    <ModuleWrapper flexDirection="column" ref={ref}>
      <Text as="h2" variant="title1" color="darkBlue">
        {t('retinaLanding.stories.title')}
      </Text>
      <Box mt={[4, 5]}>
        <Flex flexDirection={['column', 'row']}>
          <Box flex={1} height={['100%', '75rem']}>
            <StoryCard
              {...stories.sofia.card}
              onCtaClick={() => {
                selectTestimonial('sofia');
              }}
            />
          </Box>
          <Flex flex={1} flexDirection="column" ml={[0, 6]}>
            <Box flex={1} mt={[6, 0]}>
              <StoryCard
                {...stories.josh.card}
                onCtaClick={() => {
                  selectTestimonial('josh');
                }}
              />
            </Box>
            <Box flex={1} mt={6}>
              <StoryCard
                {...stories.marta.card}
                onCtaClick={() => {
                  selectTestimonial('marta');
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <RetinaStoryModal
        isOpen={modalOpen}
        content={testimonial}
        image={modalImage}
        onApplyClick={() => {
          setModalOpen(false);
          handleClickApply();
        }}
        onRequestClick={() => {
          setModalOpen(false);
          handleClickRequest();
        }}
        onClose={() => setModalOpen(false)}
      />
    </ModuleWrapper>
  );
}

function buildStoriesData(
  testimonials: any,
  t: Function
): { [key in AlumniId]: any } {
  const summaries = t('retinaLanding.stories', {
    returnObjects: true
  });
  return {
    josh: {
      card: {
        ...summaries.first,
        picture: '/assets/retina/second_story_bg.png'
      },
      testimonial: testimonials['josh']
    },
    sofia: {
      card: {
        ...summaries.second,
        picture: '/assets/retina/main_story_bg.png'
      },
      testimonial: testimonials['sofia']
    },
    marta: {
      card: {
        ...summaries.third,
        picture: '/assets/retina/third_story_bg.png'
      },
      testimonial: testimonials['marta']
    }
  };
}

export default React.forwardRef<HTMLDivElement>(RetinaStories);
