import React from 'react';
import { Flex } from 'rebass/styled-components';

import useTheme from '../hooks/useTheme';

interface Props {
  children?: React.ReactNode;
}

function ModuleWrapper(props: Props, ref): JSX.Element {
  const { children, ...rest } = props;
  const { layout } = useTheme();

  return (
    <Flex
      ref={ref}
      width={[layout.mobile.width, layout.desktop.width]}
      px={[layout.mobile.padding, layout.desktop.padding]}
      mt={[6, 7]}
      flexDirection={['column', 'row']}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default React.forwardRef<HTMLDivElement, Props>(ModuleWrapper);
