import { updateDataLayer } from '../../lib/datalayer';

function emitRequestEvent(campus) {
  updateDataLayer({
    event: 'gaEvent',
    formName: 'retina::syllabus request',
    eventCategory: 'form',
    eventAction: 'click button',
    eventLabel: 'get more information::request syllabus',
    campus
  });
}

function emitSubmissionOkEvent({ keepInTouch, campus }) {
  updateDataLayer({
    event: 'gaEvent',
    formName: 'retina::syllabus request',
    eventCategory: 'form',
    eventAction: 'syllabus request',
    eventLabel: 'form sent::ok',
    formFields: `${campus}::${keepInTouch ? 'cb newsletter' : ''}`,
    campus
  });
}

export { emitRequestEvent, emitSubmissionOkEvent };
