import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import useTheme from '../../../hooks/useTheme';

import Card from '../../../components/atoms/layout/Card';
import ReviewScore from '../../../components/RetinaReviewScore';
import RetinaApplyForm from '../../../components/RetinaApplyForm';

function RetinaApply(props: any, ref): JSX.Element {
  const { cohorts } = props;
  const { t } = useTranslation(['retina-landing']);
  const {
    layout: { desktop, mobile }
  } = useTheme();

  return (
    <Flex
      id="apply-form"
      width={[mobile.width, desktop.width]}
      px={['', desktop.padding]}
      ref={ref}
    >
      <Card borderRadius={['', 'card']} bg={['', '#EEEDFF']} mb={[0, 200]}>
        <Flex
          flexWrap={['nowrap', 'wrap']}
          flexDirection={['column', 'row']}
          height={['']}
          mb={[0, 7]}
        >
          <Box
            py={[5, 0]}
            px={[mobile.padding, '']}
            bg={['#EEEDFF', '']}
            width={[1, 432]}
            ml={[0, 8]}
            mt={[0, 7]}
          >
            <Text width={[1, 350]} as="h3" variant="title1" color="darkBlue">
              {t('retina-landing:retinaLanding.apply.bookTitle')}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {t('retina-landing:retinaLanding.apply.bookParagraphs.first')}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {t('retina-landing:retinaLanding.apply.bookParagraphs.second')}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {t('retina-landing:retinaLanding.apply.bookParagraphs.third')}
            </Text>
          </Box>
          <Box
            px={[mobile.padding, '']}
            width={[1, 512]}
            height={['', 200]}
            ml={[0, 7]}
            mt={[0, 6]}
          >
            <Card
              bg={'white'}
              boxShadow={['none', '0px 4px 8px rgba(0, 0, 0, 0.08)']}
              borderRadius={[0, 5]}
              px={[0, 6]}
              pt={6}
              pb={5}
            >
              <RetinaApplyForm cohorts={cohorts} />
            </Card>
          </Box>
          <Box
            px={[mobile.padding, '']}
            width={[300, 333]}
            ml={[0, 8]}
            mt={[9, 6]}
          >
            <ReviewScore />
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}

export default React.forwardRef<HTMLDivElement>(RetinaApply);
