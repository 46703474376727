import styled from 'styled-components';
import { border, space, color, typography } from 'styled-system';

const Error = styled.div`
  ${border}
  ${space}
  ${color}
  ${typography}
`;

export { Error };
