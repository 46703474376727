const defaultCampus = (language: string): string => {
  switch (language) {
    case 'br':
      return 'sao';
    case 'de':
      return 'ber';
    case 'en':
      return 'mia';
    case 'es':
      return 'mad';
    case 'fr':
      return 'par';
    case 'mx':
      return 'mex';
    case 'nl':
      return 'ams';
    case 'pt':
      return 'lis';
    default:
      return '';
  }
};

export default defaultCampus;
