import React from 'react';
import { Flex, Text } from 'rebass/styled-components';

interface Props {
  review: string;
  name: string;
  role: string;
}

function RetinaAlumniReview(props: Props): JSX.Element {
  const { review, name, role } = props;
  return (
    <Flex flexDirection="column">
      <Text as="p" variant="body4" color="darkBlues.80">
        {review}
      </Text>
      <Flex flexDirection="row" mt={5}>
        <Flex flexDirection="column">
          <Text as="p" variant="body2" color="darkBlue">
            {name}
          </Text>
          <Text as="p" variant="body4" color="darkBlue">
            {role}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default RetinaAlumniReview;
