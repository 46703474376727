import moment from 'moment';
import { updateDataLayer } from '../../lib/datalayer';

interface Cohort {
  start_at: string;
  end_at: string;
  language: string;
  price_currency: string;
  price_discounted: number;
  price_value: number;
}

interface FormValues {
  campus: string;
  course: string;
  keepInTouch: boolean;
}

function emitApplyEvent(campus: string) {
  updateDataLayer({
    event: 'gaEvent',
    formName: 'retina::apply form',
    eventCategory: 'form',
    eventAction: 'click button',
    eventLabel: 'apply to ironhack::apply',
    campus
  });
}

function emitSubmissionOkEvent(
  { campus, course, keepInTouch }: FormValues,
  cohort: Cohort
) {
  updateDataLayer({
    event: 'gaEvent',
    formName: 'retina::apply form',
    eventCategory: 'form',
    eventAction: 'apply form',
    eventLabel: 'form sent::ok',
    formFields: `${campus}::${course}::${keyFromCohort(cohort)}${
      keepInTouch ? '::cb newsletter' : ''
    }`,
    campus
  });
}

function keyFromCohort(cohort: Cohort) {
  const startDate = moment(cohort.start_at).format('DD MMM');
  const endDate = moment(cohort.end_at).format('DD MMM');
  return `${startDate} - ${endDate}|${
    cohort.language
  }|${cohort.price_discounted || cohort.price_value}${cohort.price_currency}`;
}

export { emitApplyEvent, emitSubmissionOkEvent };
