import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Img
} from '@chakra-ui/react';

import ContextualCtas from '../../../../../components/ContextualCtas';

// Temporary! refactor candidate
import Body from '../../../../../components/BlogBody';

function RetinaStoryModal(props: any): JSX.Element {
  const { t } = useTranslation(['retina-landing']);

  const {
    onApplyClick,
    onRequestClick,
    onClose,
    isOpen,
    content,
    image
  } = props;

  const ctasContent = t('retinaLanding.stories.modal.contextualCtas', {
    returnObjects: true
  });

  return (
    <Modal
      returnFocusOnClose={false}
      size="full"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={['', 'card']}
        height=""
        width={['100%', 'desktopWidth']}
      >
        <ModalCloseButton
          borderRadius="25px"
          backgroundColor="white"
          mt={1}
          mr={1}
        />
        <ModalBody p={0} mb={[4, '']}>
          <Flex direction="column" px={['', '12rem']} pt={['', 10]}>
            <Img
              borderRadius={['', 'card']}
              height={334}
              src={image}
              objectFit="cover"
            />
            <Box px={[3, '']}>
              <Body content={content} />
            </Box>
          </Flex>
          <ContextualCtas
            content={ctasContent}
            onPrimaryClick={onRequestClick}
            onSecondaryClick={onApplyClick}
          />
          <Box //Fixes the bottom border radius
            backgroundColor="electricBlues.10"
            pb={['', 1.5]}
            borderRadius={['', 'card']}
          ></Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RetinaStoryModal;
