import Accordion from './accordion';
import ButtonStyles from './button';
import Link from './link';
import Typography from '../typography';
import Tag from './tag';
import Tooltip from './tooltip';

export default {
  Accordion,
  Button: { variants: ButtonStyles.buttons },
  Link,
  Tag,
  Text: { variants: Typography.text },
  Tooltip
};
