import _ from 'lodash/fp';
import { FORM_ERROR } from 'final-form';
import { navigate } from 'gatsby';
import { Flex, Box, Text, Card } from 'rebass/styled-components';
import React, { useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import { emitRequestEvent, emitSubmissionOkEvent } from './ga_events';
import { SyllabusFormProps, SelectOption, StringMap } from './interfaces';
import { submitSyllabusForm } from './submission';
import { getLinkByPageName } from '../../lib/links';

import InputText from '../Forms/RFF-fields/InputText';
import InputCheckbox from '../Forms/RFF-fields/InputCheckbox';
import InputSelect from '../Forms/RFF-fields/InputSelect';
import SubmitButton from '../atoms/buttons/Button';

const getCampuses = (t: Function): SelectOption[] => [
  { value: 'ams', text: t('breadcrumbs:breadcrumbs.campusAms') },
  { value: 'bcn', text: t('breadcrumbs:breadcrumbs.campusBcn') },
  { value: 'ber', text: t('breadcrumbs:breadcrumbs.campusBer') },
  { value: 'lis', text: t('breadcrumbs:breadcrumbs.campusLis') },
  { value: 'mad', text: t('breadcrumbs:breadcrumbs.campusMad') },
  { value: 'mex', text: t('breadcrumbs:breadcrumbs.campusMex') },
  { value: 'mia', text: t('breadcrumbs:breadcrumbs.campusMia') },
  { value: 'par', text: t('breadcrumbs:breadcrumbs.campusPar') },
  { value: 'sao', text: t('breadcrumbs:breadcrumbs.campusSao') },
  { value: 'rmt', text: t('breadcrumbs:breadcrumbs.campusRmt') }
];

const getCourses = (t: Function): StringMap => ({
  cysec: t('course-list:courseList.cysec.title'),
  data: t('course-list:courseList.data.title'),
  uxui: t('course-list:courseList.uxui.title'),
  web: t('course-list:courseList.web.title')
});

const RetinaSyllabusForm: React.FunctionComponent<any> = (
  props: SyllabusFormProps
) => {
  const { course } = props;
  const { t, i18n } = useTranslation([
    'breadcrumbs',
    'course-list',
    'request-syllabus',
    'retina-landing'
  ]);

  const campuses = useMemo(() => getCampuses(t), [i18n.language]);
  const courses = useMemo(() => getCourses(t), [i18n.language]);
  const redirectURL = getLinkByPageName('retinaSyllabusThanks');

  return (
    <Form
      onSubmit={async values => {
        try {
          await submitSyllabusForm(values, course, i18n.language);
          emitSubmissionOkEvent(values);
          navigate(redirectURL);
        } catch (e) {
          return {
            [FORM_ERROR]:
              'There has been an error with your request, please try again n a few minutes...'
          };
        }
      }}
      validate={validate}
      render={({ handleSubmit, values, submitting, error, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width={1}>
                <Text variant="body2" color="darkBlues.100">
                  {t('retina-landing:retinaLanding.courses.syllabusFormTitle', {
                    course: courses[course]
                  })}
                </Text>
              </Box>
              <Box mt={6} width={[1, 1 / 2]} pr={[0, 3]}>
                <Field
                  name="firstName"
                  label={t('request-syllabus:requestSyllabus.fields.name')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 3]} mt={6}>
                <Field
                  name="lastName"
                  label={t('request-syllabus:requestSyllabus.fields.last')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="email"
                  label={t('request-syllabus:requestSyllabus.fields.email')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="campus"
                  label={t('request-syllabus:requestSyllabus.fields.campus')}
                  placeholder={t(
                    'request-syllabus:requestSyllabus.placeholders.campus'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={campuses}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="acceptPolicy"
                  component={InputCheckbox}
                  variant="hero"
                >
                  <TermsAndConditionsContent />
                </Field>
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="keepInTouch"
                  component={InputCheckbox}
                  variant="hero"
                >
                  {t('request-syllabus:requestSyllabus.newsletter')}
                </Field>
              </Box>
              {submitError && !submitting && (
                <Box fontSize={2} mt={6} mx={4} width={1}>
                  <Card
                    pt={5}
                    pb={5}
                    textAlign="center"
                    borderRadius={6}
                    color={'criticals.100'}
                    bg={'criticals.80'}
                  >
                    {submitError}
                  </Card>
                </Box>
              )}
              <Box mt={6} width={1}>
                <SubmitButton
                  type="submit"
                  py={4}
                  variant="secondary"
                  disabled={error}
                  onClick={() => emitRequestEvent(values.campus)}
                  label={t('request-syllabus:requestSyllabus.fields.button')}
                  submitting={submitting}
                />
              </Box>
            </Flex>
          </form>
        );
      }}
    />
  );
};

// Validation

const validate = (values: any) => {
  const validForm =
    values.firstName &&
    values.lastName &&
    values.email &&
    values.campus &&
    values.acceptPolicy &&
    /\S+@\S+\.\S+/.test(values.email);

  return validForm ? {} : { [FORM_ERROR]: 'Missing required fields' };
};

// Terms of use content

const TermsAndConditionsContent = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t('request-syllabus:requestSyllabus.legalAdvice:byApplyingStart')}
      <a href={getLinkByPageName('privacyPolicy')}>
        &nbsp;
        {t(
          'request-syllabus:requestSyllabus.legalAdvice:privacyPolicyLinkText'
        )}
        &nbsp;
      </a>
      {t('request-syllabus:requestSyllabus.legalAdvice:byApplyingConjuntion')}
      &nbsp;
      <a href={getLinkByPageName('termsOfUse')}>
        {t('request-syllabus:requestSyllabus.legalAdvice:termsOfUseLinkText')}
      </a>
    </div>
  );
};

export default withTranslation()(RetinaSyllabusForm);
