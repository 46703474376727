import React from 'react';
import { Box, Flex, Link, Text } from 'rebass/styled-components';

import IronhackLogo from '../../../../static/assets/icons/ironhack_logos/logo.svg';
import CourseReport from '../../../../static/assets/footer/course-report.svg';
import SwitchUp from '../../../../static/assets/footer/switchup.svg';
import FloridaEducation from '../../../../static/assets/footer/florida-education.svg';

import { getLinkByPageName } from '../../../lib/links';
import ModuleWrapper from '../../ModuleWrapper';
import useTheme from '../../../hooks/useTheme';

const Logos = function(): JSX.Element {
  return (
    <Flex
      width="100%"
      justifyContent={['space-between', 'flex-start']}
      alignItems="center"
    >
      <CourseReport />
      <Box ml={[0, 6]}>
        <SwitchUp />
      </Box>
      <Flex
        flexDirection={['column', 'row']}
        ml={[0, 6]}
        alignItems={[null, 'center']}
      >
        <Text as="p" variant="body5" color="whites.80">
          Licensed by:
        </Text>
        <Box ml={[0, 2]} mt={[1, 0]}>
          <FloridaEducation />
        </Box>
      </Flex>
    </Flex>
  );
};

function RetinaFooter(): JSX.Element {
  const { layout } = useTheme();
  return (
    <ModuleWrapper
      width="100%"
      backgroundColor="darkBlue"
      height={['auto', '11.3rem']}
      px={0}
      justifyContent="center"
    >
      <Flex
        width={[layout.mobile.width, layout.desktop.width]}
        px={[layout.mobile.padding, layout.desktop.padding]}
        justifyContent={['center', 'space-between']}
        flexDirection={['column', 'row']}
      >
        <Flex
          alignItems="center"
          order={[1, 0]}
          flexDirection={['column', 'row']}
        >
          <Flex order={[1, 0]} mt={[4, 0]} mb={[6, 0]}>
            <Link href={getLinkByPageName('home')}>
              <IronhackLogo alt="Ironhack Logo" />
            </Link>
          </Flex>
          <Flex
            order={[0, 1]}
            alignItems={['flex-start', 'center']}
            mt={[6, 0]}
            ml={[0, 4]}
          >
            <Text as="p" variant="body5" color="whites.80">
              990 Biscayne Blvd. Ste 503 - Miami FL 33132
              <br />© 2013-2021 Ironhack. All Rights Reserved
            </Text>
          </Flex>
        </Flex>
        <Flex mt={[5, 0]} order={[0, 2]} width={['100%', 'auto']}>
          <Logos />
        </Flex>
      </Flex>
    </ModuleWrapper>
  );
}

export default RetinaFooter;
