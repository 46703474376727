import _ from 'lodash/fp';
import { FORM_ERROR } from 'final-form';
import { navigate } from 'gatsby';
import moment from 'moment';
import { Flex, Box, Text } from 'rebass/styled-components';
import React, { useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import { emitApplyEvent, emitSubmissionOkEvent } from './ga_events';
import { ApplyFormProps, SelectOption, StringMap } from './interfaces';
import Cohort from '../interfaces/Cohorts';
import { Error } from './styles';
import { submitApplyForm } from './submission';
import { getLinkWithoutDomain } from '../../lib/links';

import InputText from '../Forms/RFF-fields/InputText';
import InputCheckbox from '../Forms/RFF-fields/InputCheckbox';
import InputSelect from '../Forms/RFF-fields/InputSelect';
import InputPhone from '../Forms/RFF-fields/InputPhone';
import SubmitButton from '../atoms/buttons/Button';

const courses: SelectOption[] = [
  { value: 'webft', text: 'Web Development Full-Time' },
  { value: 'webpt', text: 'Web Development Part-Time' },
  { value: 'uxuift', text: 'UX/UI Design Full-Time' },
  { value: 'uxuipt', text: 'UX/UI Design Part-Time' },
  { value: 'dataft', text: 'Data Analytics Full-Time' },
  { value: 'datapt', text: 'Data Analytics Part-Time' },
  { value: 'cysecft', text: 'Cybersecurity Full-Time' },
  { value: 'cysecpt', text: 'Cybersecurity Part-Time' }
];

const getCampuses = (t: Function): SelectOption[] => [
  { value: 'ams', text: t('breadcrumbs:breadcrumbs.campusAms') },
  { value: 'bcn', text: t('breadcrumbs:breadcrumbs.campusBcn') },
  { value: 'ber', text: t('breadcrumbs:breadcrumbs.campusBer') },
  { value: 'lis', text: t('breadcrumbs:breadcrumbs.campusLis') },
  { value: 'mad', text: t('breadcrumbs:breadcrumbs.campusMad') },
  { value: 'mex', text: t('breadcrumbs:breadcrumbs.campusMex') },
  { value: 'mia', text: t('breadcrumbs:breadcrumbs.campusMia') },
  { value: 'par', text: t('breadcrumbs:breadcrumbs.campusPar') },
  { value: 'sao', text: t('breadcrumbs:breadcrumbs.campusSao') },
  { value: 'rmt', text: t('breadcrumbs:breadcrumbs.campusRmt') }
];

const getLanguageNames = (t: Function): StringMap => ({
  en: t('languages:languages.en'),
  es: t('languages:languages.es'),
  fr: t('languages:languages.fr'),
  pt: t('languages:languages.pt'),
  br: t('languages:languages.br'),
  nl: t('languages:languages.nl'),
  de: t('languages:languages.de')
});

const ApplyFormLanding: React.FunctionComponent<any> = (
  props: ApplyFormProps
) => {
  const { cohorts } = props;
  const { t, i18n } = useTranslation([
    'application-form',
    'breadcrumbs',
    'languages'
  ]);

  moment.locale(i18n.language);
  const campuses = useMemo(() => getCampuses(t), [i18n.language]);
  const languageNames = useMemo(() => getLanguageNames(t), [i18n.language]);

  const cohortOptions = useMemo(
    () => buildCohortOptions(languageNames, cohorts),
    [languageNames, cohorts]
  );
  const courseOptions = useMemo(() => buildCourseOptions(cohortOptions), [
    cohortOptions
  ]);

  const redirectURL = getLinkWithoutDomain('retinaApplyThanks');

  return (
    <Form
      onSubmit={async values => {
        const cohort = cohorts.find(c => c.id === values.edition);
        if (!cohort) return { [FORM_ERROR]: 'Missing required fields' };
        try {
          await submitApplyForm(values, cohort, i18n.language);
          emitSubmissionOkEvent(values, cohort);
          navigate(redirectURL);
        } catch (e) {
          return {
            [FORM_ERROR]:
              'There has been an error with your application, please try again in a few minutes...'
          };
        }
      }}
      validate={validate}
      render={({ handleSubmit, values, submitting, error, submitError }) => {
        const { campus, course } = values;
        return (
          <form onSubmit={handleSubmit}>
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width={[1, 1 / 2]} pr={[0, 3]}>
                <Field
                  name="firstName"
                  label={t('application-form:applicationForm.fields.firstName')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 3]} mt={[5, 0]}>
                <Field
                  name="lastName"
                  label={t('application-form:applicationForm.fields.lastName')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="email"
                  label={t('application-form:applicationForm.fields.email')}
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="phone"
                  label={t(
                    'application-form:applicationForm.fields.phoneNumber'
                  )}
                  component={InputPhone}
                  variant="hero"
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="campus"
                  label={t('application-form:applicationForm.fields.campus')}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.campus'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={campuses}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="course"
                  label={t('application-form:applicationForm.fields.bootcamp')}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.course'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={_.getOr([], campus, courseOptions)}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="edition"
                  label={t(
                    'application-form:applicationForm.fields.course_date'
                  )}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.edition'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={_.getOr([], `${campus}.${course}`, cohortOptions)}
                />
              </Box>
              <Box mt={5} width={1}>
                <Text variant="body5" color="darkBlues.100">
                  {t('application-form:applicationForm.requiredFields')}
                </Text>
              </Box>
              <Box mt={4} width={1}>
                <Field
                  name="acceptPolicy"
                  component={InputCheckbox}
                  variant="hero"
                >
                  <TermsAndConditionsContent />
                </Field>
              </Box>
              <Box mt={4} width={1}>
                <Field
                  name="keepInTouch"
                  component={InputCheckbox}
                  variant="hero"
                >
                  {t('application-form:applicationForm.footer.newsletter')}
                </Field>
              </Box>
              {submitError && !submitting && (
                <Box fontSize={2} mt={6} mx={4} width={1}>
                  <Error
                    pt={5}
                    pb={5}
                    textAlign="center"
                    borderRadius={6}
                    color={'criticals.100'}
                    bg={'criticals.80'}
                  >
                    {submitError}
                  </Error>
                </Box>
              )}
              <Box mt={5} width={1}>
                <SubmitButton
                  type="submit"
                  height={[42, 56]}
                  variant="primary"
                  onClick={() => emitApplyEvent(campus)}
                  disabled={error}
                  label={t('application-form:applicationForm.fields.button')}
                  submitting={submitting}
                />
              </Box>
            </Flex>
          </form>
        );
      }}
    />
  );
};

// Validation

const validate = (values: any) => {
  const validForm =
    values.firstName &&
    values.lastName &&
    values.email &&
    values.phone &&
    values.campus &&
    values.course &&
    values.edition &&
    values.acceptPolicy &&
    /\S+@\S+\.\S+/.test(values.email);

  return validForm ? {} : { [FORM_ERROR]: 'Missing required fields' };
};

// Terms of use content

const TermsAndConditionsContent = () => {
  const { t } = useTranslation(['application-form']);
  return (
    <div>
      {t('application-form:applicationForm.footer.legalAdvice.byApplyingStart')}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={t(
          'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkHref'
        )}
      >
        &nbsp;
        {t(
          'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkText'
        )}
        &nbsp;
      </a>
      {t(
        'application-form:applicationForm.footer.legalAdvice.byApplyingConjuntion'
      )}
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={t(
          'application-form:applicationForm.footer.legalAdvice.termsOfUseLinkHref'
        )}
      >
        {t(
          'application-form:applicationForm.footer.legalAdvice.termsOfUseLinkText'
        )}
      </a>
    </div>
  );
};

// Aux functions

function buildCohortOptions(languageNames: StringMap, rawCohorts: Cohort[]) {
  return _.pipe(
    _.groupBy('campus_code'),
    _.mapValues(
      _.pipe(
        _.groupBy('course_code'),
        _.mapValues(
          _.map((cohort: Cohort) => ({
            value: cohort.id,
            text: buildCohortText(languageNames, cohort)
          }))
        )
      )
    )
  )(rawCohorts);
}

function buildCourseOptions(cohortOptions: any) {
  return _.mapValues(
    _.pipe(_.keys, cohortCourses =>
      _.filter(
        courseOption =>
          _.contains(_.prop('value', courseOption), cohortCourses),
        courses
      )
    ),
    cohortOptions
  );
}

function buildCohortText(languageNames: StringMap, cohort: Cohort) {
  const startDate = moment(cohort.start_at).format('DD MMM');
  const endDate = moment(cohort.end_at).format('DD MMM');
  const languageName = languageNames[cohort.language];
  return `${startDate} - ${endDate} :: ${languageName} :: ${cohort.price_discounted ||
    cohort.price_value}${cohort.price_currency}`;
}

export default withTranslation()(ApplyFormLanding);
