import _ from 'lodash/fp';
import React, { useMemo, useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import useScroll from '../../../hooks/useScroll';

import SyllabusForm from '../../../components/RetinaSyllabusForm';
import ModuleWrapper from '../../ModuleWrapper';
import Card from '../../../components/atoms/layout/Card';

import CourseCard from './components/CourseCard';
import { emitInterestEvent } from './ga_events';

import WebdevIcon from '../../../../static/assets/retina/course-webdev.svg';
import UxuiIcon from '../../../../static/assets/retina/course-uxui.svg';
import DataIcon from '../../../../static/assets/retina/course-data.svg';
import CyberIcon from '../../../../static/assets/retina/course-cyber.svg';

const getCourses = (t: Function) => ({
  web: {
    title: t('course-list:courseList.web.title'),
    description: t('retina-landing:retinaLanding.courses.descriptions.web'),
    Icon: WebdevIcon
  },
  uxui: {
    title: t('course-list:courseList.uxui.title'),
    description: t('retina-landing:retinaLanding.courses.descriptions.uxui'),
    Icon: UxuiIcon
  },
  data: {
    title: t('course-list:courseList.data.title'),
    description: t('retina-landing:retinaLanding.courses.descriptions.data'),
    Icon: DataIcon
  },
  cysec: {
    title: t('course-list:courseList.cysec.title'),
    description: t('retina-landing:retinaLanding.courses.descriptions.cyber'),
    Icon: CyberIcon
  }
});

function RetinaCourses(props, ref): JSX.Element {
  const { campus, cohorts = [] } = props;
  const prices = useMemo(() => getPrices(campus, cohorts), [campus, cohorts]);
  const { t, i18n } = useTranslation(['course-list', 'retina-landing']);
  const courseData = useMemo(() => getCourses(t), [i18n.language]);
  const [course, setCourse] = useState('');
  const [scrollToSyllabus, syllabusRef] = useScroll({ block: 'center' });

  useEffect(() => {
    if (course) {
      scrollToSyllabus();
    }
  }, [course]);

  return (
    <Flex
      justifyContent="center"
      width={1}
      bg={'#FAFBFF'}
      mt={[6, 8]}
      ref={ref}
    >
      <Box>
        <ModuleWrapper flexWrap={['nowrap', 'wrap']} pb={[6, 7]}>
          <Box width={1}>
            <Text color={'darkBlue'} variant="title1">
              {t('retina-landing:retinaLanding.courses.title')}
            </Text>
          </Box>
          <Box mt={[5, 6]}>
            <CourseCard
              onClickDownload={() => {
                emitInterestEvent('web');
                setCourse('web');
              }}
              price={prices.web}
              {...courseData.web}
            />
          </Box>
          <Box mt={[5, 6]} ml={[0, 6]}>
            <CourseCard
              onClickDownload={() => {
                emitInterestEvent('uxui');
                setCourse('uxui');
              }}
              price={prices.uxui}
              {...courseData.uxui}
            />
          </Box>
          <Box mt={[5, 6]}>
            <CourseCard
              onClickDownload={() => {
                emitInterestEvent('data');
                setCourse('data');
              }}
              price={prices.data}
              {...courseData.data}
            />
          </Box>
          <Box mt={[5, 6]} ml={[0, 6]}>
            <CourseCard
              onClickDownload={() => {
                emitInterestEvent('cysec');
                setCourse('cysec');
              }}
              price={prices.cysec}
              {...courseData.cysec}
            />
          </Box>
          {course && (
            <Flex
              ref={syllabusRef}
              justifyContent="center"
              width={1}
              mt={[7, 8]}
            >
              <Card
                width={['', 544]}
                px={[0, 7]}
                py={[0, 6]}
                bg={['', 'white']}
                boxShadow={['', 'active3']}
              >
                <SyllabusForm course={course} />
              </Card>
            </Flex>
          )}
        </ModuleWrapper>
      </Box>
    </Flex>
  );
}

function getPrices(campus: string, cohorts) {
  const getPriceForCourses = (track: string) => {
    return _.pipe(
      _.filter(_.whereEq({ campus_code: campus, course_code: track })),
      _.minBy(c => c.price_discounted || c.price_value),
      _.defaultTo({
        price_value: '',
        price_discounted: '',
        price_currency: ''
      }),
      c => `${c.price_discounted || c.price_value}${c.price_currency}`
    )(cohorts);
  };

  return {
    web: getPriceForCourses('webft'),
    data: getPriceForCourses('dataft'),
    cysec: getPriceForCourses('cysecft'),
    uxui: getPriceForCourses('uxuift')
  };
}

export default React.forwardRef<HTMLDivElement>(RetinaCourses);
