import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import LastStar from '../../../../../../static/assets/retina/LastStar.svg';
import Star from '../../../../../../static/assets/retina/Star.svg';

function RetinaReviewScore(): JSX.Element {
  const { t } = useTranslation(['retina-landing']);
  return (
    <Flex flexDirection="column">
      <Text
        as="h6"
        fontFamily="Gelion-SemiBold"
        lineHeight="6.5rem"
        fontSize="5rem"
        color="darkBlue"
      >
        {t('retinaLanding.reviews.score')}
      </Text>
      <Text
        as="p"
        variant="body4"
        color="darkBlue"
        dangerouslySetInnerHTML={{
          __html: t('retinaLanding.reviews.scoreClaim')
        }}
      />
      <Flex flexDirection="row" mt={4}>
        <Box mr={1}>
          <Star />
        </Box>
        <Box mr={1}>
          <Star />
        </Box>
        <Box mr={1}>
          <Star />
        </Box>
        <Box mr={1}>
          <Star />
        </Box>
        <LastStar />
      </Flex>
    </Flex>
  );
}

export default RetinaReviewScore;
