import React from 'react';
import { Box, Button, Flex, Link, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import { getLinkByPageName } from '../../../../../lib/links';
import IronhackLogo from '../../../../../../static/assets/icons/ironhack_logos/logo.svg';

interface Props {
  menuOneHandler: () => void;
  menuTwoHandler: () => void;
  menuCtaHandler: () => void;
}

function RetinaHeader(props: Props): JSX.Element {
  const { t } = useTranslation(['retina-landing']);
  const { menuOneHandler, menuTwoHandler, menuCtaHandler } = props;

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box sx={{ width: ['3.8rem', '4.8rem'] }}>
        <Link href={getLinkByPageName('home')}>
          <IronhackLogo alt="Ironhack Logo" viewBox="0 0 48 48" width="100%" />
        </Link>
      </Box>
      <Flex flexDirection="row" alignItems="center">
        <Box
          display={['none', 'block']}
          sx={{ cursor: 'pointer' }}
          onClick={menuOneHandler}
        >
          <Text
            as="h3"
            variant="body4"
            fontWeight="bold"
            color="white"
            cursor="pointer"
          >
            {t('retinaLanding.hero.header.menuOption1')}
          </Text>
        </Box>
        <Box
          ml="2rem"
          display={['none', 'block']}
          sx={{ cursor: 'pointer' }}
          onClick={menuTwoHandler}
        >
          <Text as="h3" variant="body4" fontWeight="bold" color="white">
            {t('retinaLanding.hero.header.menuOption2')}
          </Text>
        </Box>
        <Box ml={[0, '2rem']}>
          <Button
            variant="primaryS"
            fontSize={['1.3rem', '1.4rem']}
            lineHeight={['1.82rem', '1.96rem']}
            sx={{ px: ['0.8rem', '1.6rem'], py: ['0.6rem', '1rem'] }}
            onClick={menuCtaHandler}
          >
            {t('retinaLanding.hero.header.cta')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
}

export default RetinaHeader;
