import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import ReviewScore from './components/RetinaReviewScore';
import AlumniReview from './components/RetinaAlumniReview';
import ModuleWrapper from '../../ModuleWrapper';

function RetinaReviews(): JSX.Element {
  const { t } = useTranslation(['retina-landing']);

  const { reviews = {} } = t('retinaLanding.reviews', {
    returnObjects: true
  });

  return (
    <ModuleWrapper flexDirection="column">
      <Text
        as="h2"
        variant="title1"
        color="darkBlue"
        sx={{ '&>span': { color: 'electricBlue' } }}
        dangerouslySetInnerHTML={{
          __html: t('retinaLanding.reviews.title')
        }}
      />
      <Flex flexDirection={['column', 'row']} mt={[4, 6]}>
        <Box flex={1}>
          <ReviewScore />
        </Box>
        <Box flex={1} ml={[0, 10]} mt={[6, 0]}>
          <AlumniReview {...reviews.first} />
        </Box>
        <Box flex={1} ml={[0, 6]} mt={[6, 0]}>
          <AlumniReview {...reviews.second} />
        </Box>
      </Flex>
    </ModuleWrapper>
  );
}

export default RetinaReviews;
