import _ from 'lodash/fp';
import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import withI18next from '../../i18n/TemplateI18n';
import PropsRetinaLanding from '../../interface/template';
import getDefaultLocation from '../../lib/defaultLocation';
import rebassTheme from '../../theme/rebassTheme';
import chakraTheme from '../../theme/rebassTheme/chakraTheme';
import useScroll from '../../hooks/useScroll';
import RetinaWhyIronhack from '../../modules/retina/RetinaWhyIronhack';
import RetinaFooter from '../../modules/retina/RetinaFooter';
import RetinaFeatures from '../../modules/retina/RetinaFeatures';
import RetinaStories from '../../modules/retina/RetinaStories';
import RetinaReviews from '../../modules/retina/RetinaReviews';
import RetinaApply from '../../modules/retina/RetinaApply';
import RetinaCompanies from '../../modules/retina/RetinaCompanies';
import RetinaVideo from '../../modules/retina/RetinaVideo';
import RetinaHero from '../../modules/retina/RetinaHero';
import RetinaCourses from '../../modules/retina/RetinaCourses';

const BrandLandingPage = (props: PropsRetinaLanding): JSX.Element => {
  const {
    pageContext: { cohorts = [] },
    data,
  } = props;

  const { i18n } = useTranslation();

  const extendedTheme = chakraTheme;

  const [filteredCohorts, setFilteredCohorts] = useState(cohorts);
  const [defaultCampus, setDefaultCampus] = useState('');
  const [scrollToStories, storiesRef] = useScroll<HTMLDivElement>();
  const [scrollToWhyIronhack, whyIronhackRef] = useScroll<HTMLDivElement>();
  const [scrollToApply, applyRef] = useScroll<HTMLDivElement>();
  const [scrollToCourses, coursesRef] = useScroll<HTMLDivElement>();

  const testimonials = useMemo(
    () =>
      _.pipe(
        _.map(
          _.update(['node', 'locale'], (locale) =>
            locale === 'pt-BR' ? 'br' : locale
          )
        ),
        _.filter(_.pathEq('node.locale', i18n.language)),
        _.over([_.map('node.alumniId'), _.map('node.content')]),
        _.apply(_.zipObj)
      )(data.allDatoCmsAlumniTestimonial.edges),
    [data, i18n.language]
  );

  const filterCohortsByLocation = async () => {
    const { campus, remoteTimezone } = await getDefaultLocation(i18n.language);
    if (remoteTimezone) {
      setFilteredCohorts(
        _.remove(
          _.allPass([
            _.propEq('campus_code', 'rmt'),
            _.negate(_.propEq('timezone', remoteTimezone)),
          ]),
          cohorts
        )
      );
    }
    setDefaultCampus(campus);
  };

  useEffect(() => {
    filterCohortsByLocation();
  }, [cohorts, i18n.language]);

  return (
    <>
      <ThemeProvider theme={rebassTheme}>
        <ChakraProvider theme={extendedTheme}>
          <Flex alignItems="center" flexDirection="column" width="100%">
            <RetinaHero
              handlers={{
                menuOne: scrollToStories,
                menuTwo: scrollToCourses,
                menuCta: scrollToApply,
                mainCta: scrollToCourses,
                secondaryCta: scrollToWhyIronhack,
              }}
            />
            <RetinaFeatures />
            <RetinaWhyIronhack ref={whyIronhackRef} />
            <RetinaCompanies companies={data.datoCmsCompanyShowcase.main} />
            <RetinaStories
              handleClickApply={scrollToApply}
              handleClickRequest={scrollToCourses}
              ref={storiesRef}
              testimonials={testimonials}
            />
            <RetinaCourses
              campus={defaultCampus}
              cohorts={filteredCohorts}
              ref={coursesRef}
            />
            <RetinaReviews />
            <RetinaVideo />
            <Box mt={[3, 4]}>
              <RetinaApply cohorts={filteredCohorts} ref={applyRef} />
            </Box>
            <RetinaFooter />
          </Flex>
        </ChakraProvider>
      </ThemeProvider>
    </>
  );
};

export const query = graphql`
  query($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          in: [
            "seo"
            "languages"
            "retina-landing"
            "application-form"
            "request-syllabus"
            "breadcrumbs"
            "course-list"
          ]
        }
      }
    ) {
      ...TranslationFragment
    }
    allDatoCmsAlumniTestimonial {
      edges {
        node {
          alumniId
          content
          locale
        }
      }
    }
    datoCmsCompanyShowcase {
      main {
        name
        logo {
          fluid(maxWidth: 140, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`;

export default withI18next()(BrandLandingPage);
