import buttons from './components/button';
import typography from './typography';

export default {
  ...buttons,
  ...typography,
  breakpoints: ['1200px'],
  global: {
    color: 'darkBlues.100'
  },
  layout: {
    desktop: {
      width: '128rem',
      padding: '8rem',
      marginTop: 7
    },
    mobile: {
      width: '100%',
      padding: '2.4rem',
      marginTop: 6
    }
  },
  colors: {
    critical: '#EC3A35',
    criticals: {
      100: '#EC3A35',
      80: '#FDEBEB'
    },
    darkBlue: '#212353',
    darkBlues: {
      100: '#212353',
      80: '#4D4F75',
      64: '#717291',
      40: '#A6A7BA',
      20: '#D3D3DD',
      8: '#EDEDF1'
    },
    electricBlue: '#2DC5FA',
    electricBlues: {
      100: '#2DC5FA',
      10: '#EAF9FF',
      active: '#00ABF2',
      hover: '#00B4FF',
      disabled: '#ABE7FD'
    },
    oranges: {
      100: '#F49102'
    },
    green: '#008B9C',
    greens: {
      20: '#CCE8EB',
      10: '#E5F3F5'
    },
    orange: {
      100: '#F49102',
      20: '#FDE9CC'
    },
    white: '#FFFFFF',
    whites: {
      100: '#FFFFFF',
      80: 'rgba(255, 255, 255, 0.8)'
    }
  },
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 120],
  sizes: {
    desktopWidth: '1280px'
  },
  shadows: {
    active1: '0px 2px 6px rgba(0, 0, 0, 0.06)',
    active2: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    active3: '0px 4px 10px rgba(0, 0, 0, 0.08)',
    inactive1: '0px 2px 2px rgba(0, 0, 0, 0.04)',
    inactive2: '0px 2px 4px rgba(0, 0, 0, 0.04)',
    inactive3: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    outlineBlue: 'inset 0px 0px 0px 2px #2DC5FA',
    outlineGray: 'inset 0px 0px 0px 2px snow'
  },
  radii: {
    card: '6px',
    button: '4px',
    circle: '100%'
  }
};
