export default {
  baseStyle: {
    container: {
      fontWeight: 600,
      py: '2px;'
    }
  },
  variants: {
    light: {
      container: {
        bg: 'darkBlues.8',
        color: 'darkBlues.100',
        minHeight: '24px',
        px: '6px'
      }
    },
    dark: {
      container: {
        bg: 'darkBlues.100',
        color: 'darkBlues.8',
        minHeight: '24px',
        px: '6px'
      }
    },
    campusBadge: {
      container: {
        bg: 'electricBlue',
        color: 'white',
        minHeight: '2.2rem',
        p: 1,
        // REFACTOR - ENG-2060 - https://app.clickup.com/t/2461218/ENG-2060
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '1rem',
        fontHeight: '1.4rem'
      }
    }
  }
};
