import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import baseTheme from './index';
import components from './components/index';

const breakpoints = createBreakpoints({ sm: '1200px' });

const theme = Object.assign({}, baseTheme, { components, breakpoints });

export default extendTheme(theme);
