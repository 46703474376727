import { documentDefined } from '../../lib/utils';
import { getGaConnectorValues } from '../../lib/ga-connector';
import {
  SalesforceSyllabusRequestInterface,
  sendRetinaSyllabusRequestToPardot
} from '../../lib/salesforce-integration';

const submitSyllabusForm = async (
  fieldValues: any,
  course: string,
  language: string
) => {
  const cookies = (documentDefined() && document.cookie) || '';
  const gaConnector = getGaConnectorValues(cookies);

  const salesforceParameters: SalesforceSyllabusRequestInterface = {
    campus: fieldValues.campus,
    course: course + 'ft',
    email: fieldValues.email,
    name: fieldValues.firstName,
    last: fieldValues.lastName,
    emailOptIn: fieldValues.keepInTouch
  };
  await sendRetinaSyllabusRequestToPardot(
    salesforceParameters,
    gaConnector,
    language
  );
};

export { submitSyllabusForm };
