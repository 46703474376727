import { documentDefined } from '../../lib/utils';
import { getGaConnectorValues } from '../../lib/ga-connector';
import { Cohort } from './interfaces';
import {
  SalesforceRetinaApplicationInterface,
  sendRetinaApplicationToPardot
} from '../../lib/salesforce-integration';

const submitApplyForm = async (
  fieldValues: any,
  selectedCohort: Cohort,
  lang: string
) => {
  const cookies = (documentDefined() && document.cookie) || '';
  const gaConnector = getGaConnectorValues(cookies);

  const salesforceParameters: SalesforceRetinaApplicationInterface = {
    salesforceId: selectedCohort.salesforce_id,
    campus: fieldValues.campus,
    course: fieldValues.course,
    cohort: fieldValues.edition,
    date: selectedCohort.start_at,
    email: fieldValues.email,
    firstName: fieldValues.firstName,
    lastName: fieldValues.lastName,
    phoneNumber: fieldValues.phone,
    gaConnector,
    emailOptIn: fieldValues.keepInTouch
  };
  await sendRetinaApplicationToPardot(
    salesforceParameters,
    lang,
    selectedCohort.timezone
  );
};

export { submitApplyForm };
