import React from 'react';
import Img from 'gatsby-image';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import ModuleWrapper from '../../ModuleWrapper';

interface Props {
  companies: { name: string; logo: object }[];
}

function RetinaCompanies(props: Props): JSX.Element {
  const { t } = useTranslation(['retina-landing']);
  const { companies = [] } = props;

  return (
    <ModuleWrapper flexDirection="column" alignItems="center">
      <Text as="h3" variant="title2" color="darkBlue">
        {t('retinaLanding.partners.title')}
      </Text>
      <Flex flexDirection="row" width="100%" mt={2} flexWrap="wrap">
        {companies.map(company => (
          <Box
            key={company.name}
            flex={['1 1 25%', '1 1 12.2%']}
            sx={{
              filter: 'grayscale(1)',
              ':hover': {
                filter: 'grayscale(0)'
              }
            }}
          >
            <Img alt={company.name} fluid={company.logo.fluid} />
          </Box>
        ))}
      </Flex>
    </ModuleWrapper>
  );
}

export default RetinaCompanies;
