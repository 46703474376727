import React from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import ModuleWrapper from '../../ModuleWrapper';

function RetinaVideo(): JSX.Element {
  const { t } = useTranslation(['retina-landing']);

  return (
    <ModuleWrapper flexDirection="column">
      <Text as="h3" variant="title1" color="darkBlue">
        {t('retinaLanding.video.title')}
      </Text>
      <Flex
        width="100%"
        height="100%"
        pb="54%" //Adjust to aspect ratio
        pt="25px"
        sx={{
          position: 'relative',
          '& > iframe': {
            borderRadius: 'card',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            border: 0
          }
        }}
        mt={5}
      >
        <iframe
          src={`https://www.youtube.com/embed/iWUXsJB23K0?controls=1&modestbranding=1&rel=0&loop=1&playlist=iWUXsJB23K0`}
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Flex>
    </ModuleWrapper>
  );
}

export default RetinaVideo;
