import React from 'react';
import { Box, Button, Flex, Image } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';

import useTheme from '../../../hooks/useTheme';

import ModuleWrapper from '../../ModuleWrapper';
import RetinaHeader from './components/RetinaHeader';
import RetinaHeroContent from './components/RetinaHeroContent';

interface Props {
  handlers: {
    menuOne: () => void;
    menuTwo: () => void;
    menuCta: () => void;
    mainCta: () => void;
    secondaryCta: () => void;
  };
}

function RetinaHero(props: Props): JSX.Element {
  const { t } = useTranslation(['retina-landing']);
  const { layout } = useTheme();
  const { handlers } = props;
  return (
    <ModuleWrapper
      mt={0}
      flexDirection="column"
      width="100%"
      px={0}
      sx={{
        backgroundImage: [
          'url(/assets/retina/mobile_header_bg.png)',
          'url(/assets/retina/desktop_header_bg.png)'
        ],
        backgroundSize: 'cover'
      }}
    >
      <Box
        height={['56.1rem', '64.1rem']}
        width={[layout.mobile.width, layout.desktop.width]}
        px={[layout.mobile.padding, layout.desktop.padding]}
        alignSelf="center"
      >
        <Box mt={4}>
          <RetinaHeader
            menuOneHandler={handlers.menuOne}
            menuTwoHandler={handlers.menuTwo}
            menuCtaHandler={handlers.menuCta}
          />
        </Box>
        <Flex flexDirection="row" justifyContent="space-between">
          <Flex flexDirection="column">
            <Box mt={[7, 8]}>
              <RetinaHeroContent
                mainCtaHandler={handlers.mainCta}
                secondaryCtaHandler={handlers.secondaryCta}
              />
            </Box>
            <Flex
              mt={6}
              flexDirection={['column', 'row']}
              alignItems={['center', 'center']}
            >
              <Button
                variant="primaryS"
                sx={{
                  width: ['100%', 'auto']
                }}
                onClick={handlers.mainCta}
              >
                {t('retinaLanding.hero.mainCta')}
              </Button>
              <Box ml={[0, 6]} mt={[4, 0]} width={['100%', 'auto']}>
                <Button
                  variant="outlineBlue"
                  sx={{
                    py: ['1rem', '1.4rem'],
                    background: 'rgba(255, 255, 255, 0.1)',
                    color: 'white',
                    boxShadow: 'inset 0px 0px 0px 2px rgba(255, 255, 255, 0.4)',
                    width: ['100%', 'auto']
                  }}
                  onClick={handlers.secondaryCta}
                >
                  {t('retinaLanding.hero.secondaryCta')}
                </Button>
              </Box>
            </Flex>
          </Flex>
          <Box mt="2.6rem" display={['none', 'block']}>
            <Image src="/assets/retina/alumni.png" height="100%" />
          </Box>
        </Flex>
      </Box>
    </ModuleWrapper>
  );
}

export default RetinaHero;
